import './component.module.css'
import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['content']
  static values = { openOnRender: Boolean }

  connect () {
    if (this.openOnRenderValue) {
      this.element.showModal()
    }
  }

  handleClickOutsideContent (event) {
    if (this.element.open && !this.contentTarget.contains(event.target)) {
      this.element.close()
    }
  }
}
